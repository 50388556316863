import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import ForwardIcon from '@material-ui/icons/Forward';
import { Link as RouterLink } from 'react-router-dom';

class HitsActions extends React.Component {
  copyLink = (surahNumber, ayahNumber) => {
    const link =
      'https://thesacredmessage.com/' +
      surahNumber.toString() +
      '/' +
      ayahNumber.toString();
    navigator.clipboard.writeText(link);
  };
  render() {
    return (
      <div>
        <Tooltip title="Copy Link to Ayah">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Copy Link"
            onClick={() => {
              this.copyLink(this.props.surahNumber, this.props.ayahNumber);
            }}
          >
            <LinkIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Go to Ayah">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Go To Ayah"
            component={RouterLink}
            to={
              '/' +
              this.props.surahNumber.toString() +
              '/' +
              this.props.ayahNumber.toString()
            }
          >
            <ForwardIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default HitsActions;
