import React from 'react';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ForwardIcon from '@material-ui/icons/Forward';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const HitsSurahDisplay = ({ hits }) => {
  return hits.map(hit => (
    <div key={hit.surahNumber.toString()} style={{ padding: 10 }}>
      <Card elevation={2} style={{ backgroundColor: '#ECEFF1' }}>
        <CardContent>
          <Grid container spacing={1} justify="flex-start">
            <Grid item xs={2}>
              <Typography
                variant="subtitle2"
                align="left"
                style={{ padding: 10 }}
              >
                {hit.surahNumber}: {hit.englishName} ({hit.englishMeaning}) |{' '}
                {hit.arabicName}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Go to Surah">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="Go To Surah"
                  component={RouterLink}
                  to={'/' + hit.surahNumber.toString()}
                >
                  <ForwardIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider variant="middle" />
    </div>
  ));
};

const SurahDisplay = connectHits(HitsSurahDisplay);

const HitsSurahContent = ({ searchState, searchResults }) => {
  return searchState &&
    searchState.query &&
    searchState.page === 1 &&
    !searchState.refinementList ? (
    <div>
      <Typography variant="h6" style={{ padding: 10 }}>
        Found Surahs for query: "{searchState.query}"
      </Typography>
      <SurahDisplay />
    </div>
  ) : (
    <div></div>
  );
};

export default connectStateResults(HitsSurahContent);
