import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const PAGE_PADDING = 5;
const SearchPagination = ({
  currentRefinement,
  nbPages,
  refine,
  createURL
}) => {
  const minPage =
    currentRefinement - PAGE_PADDING > 0 ? currentRefinement - PAGE_PADDING : 1;
  const maxPage =
    currentRefinement + PAGE_PADDING < nbPages
      ? currentRefinement + PAGE_PADDING
      : nbPages;
  if (nbPages === 0 || nbPages === 1) {
    return <div></div>;
  }
  return (
    <ButtonGroup
      fullWidth
      color="primary"
      aria-label="outlined primary button group"
    >
      <Tooltip title="First Page">
        <Button
          onClick={event => {
            event.preventDefault();
            refine(1);
            window.scrollTo(0, 0);
          }}
          key={'first'}
          href={createURL(1)}
        >
          {'<<'}
        </Button>
      </Tooltip>
      <Tooltip title="Previous Page">
        <Button
          onClick={event => {
            event.preventDefault();
            refine(currentRefinement - 1 > 0 ? currentRefinement - 1 : 1);
            window.scrollTo(0, 0);
          }}
          key={'previous'}
          href={createURL(
            currentRefinement - 1 > 0 ? currentRefinement - 1 : 1
          )}
        >
          {'<'}
        </Button>
      </Tooltip>
      {new Array(maxPage - minPage).fill(null).map((_, index) => {
        const page = minPage === 1 ? index + 1 : index + 1 + minPage;
        const style = {
          fontWeight: currentRefinement === page ? 'bold' : ''
        };

        return (
          <Button
            onClick={event => {
              event.preventDefault();
              refine(page);
              window.scrollTo(0, 0);
            }}
            key={index}
            href={createURL(page)}
            style={style}
            color={currentRefinement === page ? 'secondary' : 'primary'}
          >
            {page}
          </Button>
        );
      })}
      <Tooltip title="Next Page">
        <Button
          onClick={event => {
            event.preventDefault();
            refine(
              currentRefinement + 1 < nbPages ? currentRefinement + 1 : nbPages
            );
            window.scrollTo(0, 0);
          }}
          key={'next'}
          href={createURL(
            currentRefinement + 1 < nbPages ? currentRefinement + 1 : nbPages
          )}
        >
          {'>'}
        </Button>
      </Tooltip>
      <Tooltip title="Last Page">
        <Button
          onClick={event => {
            event.preventDefault();
            refine(nbPages);
            window.scrollTo(0, 0);
          }}
          key={'last'}
          href={createURL(nbPages)}
        >
          {'>>'}
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};

export default connectPagination(SearchPagination);
