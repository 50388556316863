import React from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const ayahSelectStyle = {
    display: 'flex',
    alignItems: 'center'
  };

function AyahSelect(props) {
    return (
        <div style={ayahSelectStyle}>
            <Typography variant="subtitle1" color="inherit" noWrap>
                Ayah
            </Typography>
            <Tooltip title="Go to Ayah">
                <IconButton
                    aria-owns={props.open ? 'menu-appbar-ayah' : undefined}
                    aria-haspopup="true"
                    onClick={props.handleMenu}
                    color="inherit"
                >
                    <ExpandMore />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar-ayah"
                anchorEl={props.anchor}
                open={props.open}
                onClose={props.handleMenuClose}
            > 
                {props.ayahNumberList.map((number, index) => (
                    <MenuItem key={index+1}
                        value={number}
                        selected={number === props.activeAyah}
                        onClick={props.handleMenuClose}>{number}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default AyahSelect;