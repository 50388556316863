import React from 'react';

const ArabicAyah = props => {
  return (
    <div
      align={props.align}
      style={{
        fontFamily: 'UthmanicHafs1Ex1Ver12',
        fontSize: '2.2rem',
        lineHeight: 1.8,
        paddingRight: 10
      }}
    >
      {props.children}
    </div>
  );
};

export default ArabicAyah;
