import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const AyahActions = props => {
  return (
    <div>
      {props.favorite ? (
        <Tooltip title="Added to Favorites">
          <IconButton edge="start" aria-label="Favorite">
            <FavoriteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Add to Favorites">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Favorite"
            onClick={props.addToFavorites}
          >
            <FavoriteBorderIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Copy Link to Ayah">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Copy Link"
          onClick={props.copyLink}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default AyahActions;
