import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import FavoriteIcon from '@material-ui/icons/Favorite';

import SimpleHeader from '../SimpleHeader';
import ArabicAyah from '../ArabicAyah';
import navBarList from '../NavBarList';
import FavoriteActions from './FavoriteActions';

class Favorites extends React.Component {
  state = { favorites: [], dataLoaded: false };

  componentDidMount() {
    //restore favorites state
    const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    this.setState({ favorites, dataLoaded: true });
  }
  copyLink = (surahNumber, ayah) => {
    const link =
      'https://thesacredmessage.com/' +
      surahNumber.toString() +
      '/' +
      ayah.ayahNumber.toString();
    navigator.clipboard.writeText(link);
  };

  removeFromFavorites = (surahNumber, ayah) => {
    // read in existing favorites
    const currentFavorites =
      JSON.parse(localStorage.getItem('favorites')) || [];
    // filter out this favorite from list
    const newFavorites = currentFavorites.filter(el => {
      return !(
        el.surahNumber === surahNumber && el.ayah.ayahNumber === ayah.ayahNumber
      );
    });
    //write favorite to favorites array in localStorage
    localStorage.setItem('favorites', JSON.stringify(newFavorites));

    this.setState({ favorites: newFavorites });
  };

  render() {
    return (
      <div>
        <div style={{ paddingTop: 80, paddingRight: 20, paddingLeft: 20 }}>
          <SimpleHeader
            title="Favorites"
            icon={() => <FavoriteIcon style={{ padding: 10 }} />}
          />
        </div>
        <main>
          {this.state.dataLoaded ? (
            this.state.favorites.map(item => (
              <div key={item.ayah.ayahNumber} style={{ padding: 10 }}>
                <Card elevation={2} style={{ backgroundColor: '#ECEFF1' }}>
                  <CardContent>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      style={{ padding: 10 }}
                    >
                      Surah {item.surahNumber}:{' '}
                      {navBarList[item.surahNumber - 1].split(' ')[2]} - Ayah:{' '}
                      {item.ayah.ayahNumber}
                    </Typography>
                    <ArabicAyah align="right">
                      {item.ayah.arabicText}
                    </ArabicAyah>
                    <Typography
                      variant="h6"
                      align="left"
                      style={{ padding: 10 }}
                    >
                      {item.ayah.englishText}
                    </Typography>
                    <FavoriteActions
                      surahNumber={item.surahNumber}
                      ayah={item.ayah}
                      copyLink={this.copyLink}
                      removeFromFavorites={this.removeFromFavorites}
                    />
                  </CardContent>
                </Card>
                <Divider variant="middle" />
              </div>
            ))
          ) : (
            <CircularProgress />
          )}
        </main>
      </div>
    );
  }
}

export default Favorites;
