import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import ForwardIcon from '@material-ui/icons/Forward';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Link as RouterLink } from 'react-router-dom';

class FavoriteActions extends React.Component {
  render() {
    return (
      <div>
        <Tooltip title="Remove from Favorites">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Remove Favorite"
            onClick={() => {
              this.props.removeFromFavorites(
                this.props.surahNumber,
                this.props.ayah
              );
            }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy Link to Ayah">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Copy Link"
            onClick={() => {
              this.props.copyLink(this.props.surahNumber, this.props.ayah);
            }}
          >
            <LinkIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Go to Ayah">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Go To Ayah"
            component={RouterLink}
            to={
              '/' +
              this.props.surahNumber.toString() +
              '/' +
              this.props.ayah.ayahNumber.toString()
            }
          >
            <ForwardIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default FavoriteActions;
