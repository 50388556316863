import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import ArabicAyah from '../ArabicAyah';
import AyahActions from './AyahActions';

class Ayah extends React.Component {
  state = {
    favorite: false
  };
  componentDidMount() {
    const found = this.props.surahFavorites.find(el => {
      return el.ayah.ayahNumber === this.props.ayah.ayahNumber;
    });
    if (found) {
      this.setState({ favorite: true });
    }
  }
  copyLink = () => {
    const link =
      'https://thesacredmessage.com/' +
      this.props.surahNumber.toString() +
      '/' +
      this.props.ayah.ayahNumber.toString();
    navigator.clipboard.writeText(link);
  };
  addToFavorites = () => {
    const favorite = {
      surahNumber: this.props.surahNumber,
      ayah: this.props.ayah
    };
    // read in existing favorites
    const currentFavorites =
      JSON.parse(localStorage.getItem('favorites')) || [];
    const newFavorites = [favorite, ...currentFavorites];

    //write favorite to favorites array in localStorage
    localStorage.setItem('favorites', JSON.stringify(newFavorites));

    this.setState({ favorite: true });
  };

  render() {
    return (
      <div>
        <Card elevation={2} style={{ backgroundColor: '#ECEFF1' }}>
          <CardContent>
            {this.props.hideArabicText === false ? (
              <ArabicAyah align={this.props.arabicTextAlignment}>
                {this.props.ayah.arabicText}
              </ArabicAyah>
            ) : (
              ''
            )}
            {this.props.hideEnglishText === false ? (
              <Typography
                variant="h6"
                align={this.props.englishTextAlignment}
                style={{ padding: 10 }}
              >
                {this.props.ayah.englishText}
              </Typography>
            ) : (
              ''
            )}
            {this.props.ayah.ayahNumber !== 0 ? (
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <AyahActions
                  favorite={this.state.favorite}
                  addToFavorites={this.addToFavorites}
                  copyLink={this.copyLink}
                />
                <Typography variant="subtitle1" align="center">
                  {'﴾' + this.props.ayah.ayahNumber + '﴿'}
                </Typography>
                <div></div>
              </Grid>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
        <Divider variant="middle" />
      </div>
    );
  }
}
export default Ayah;
