import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import BookIcon from '@material-ui/icons/Book';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Divider from '@material-ui/core/Divider';
import { Link as RouterLink } from 'react-router-dom';

const NavDrawerList = () => {
  return (
    <div role="presentation">
      <List>
        <ListItem button key="quran" component={RouterLink} to="/">
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Quran" />
        </ListItem>
        <ListItem button key="search" component={RouterLink} to="/search">
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItem>
        <ListItem button key="favorites" component={RouterLink} to="/favorites">
          <ListItemIcon>
            <FavoriteIcon />
          </ListItemIcon>
          <ListItemText primary="Favorites" />
        </ListItem>
        <ListItem
          button
          key="purchase"
          component="a"
          href="https://zawiyafellowship.com/product/the-sacred-message-of-the-holy-quran/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Purchase" />
        </ListItem>
        <ListItem button key="about" component={RouterLink} to="/about">
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
      </List>
      <Divider />
    </div>
  );
};

export default NavDrawerList;
