import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
  return (
    <FormControl fullWidth>
      <Input
        autoFocus
        type="search"
        id="search"
        placeholder={'ex "Moses" or "محمد"'}
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      {/* {isSearchStalled ? 'My search is stalled' : ''} */}
    </FormControl>
  );
};

export default connectSearchBox(SearchBox);
