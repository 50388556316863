import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';
import MenuAppBar from './Components/MenuAppBar/MenuAppBar';
import About from './Components/About/About';
import Favorites from './Components/Favorites/Favorites';
import Search from './Components/Search/Search';

function App() {
  return (
    <div>
      <BrowserRouter>
        <div>
          <Switch>
            <Route path="/" exact component={MenuAppBar} />
            <Route path="/about" component={About} />
            <Route path="/favorites" component={Favorites} />
            <Route path="/search" component={Search} />
            <Route path="/:surah?/:ayah?" component={MenuAppBar} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
