import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

import NavDrawerList from './NavDrawerList';

class SimpleHeader extends React.Component {
  state = {
    drawerOpen: false
  };
  render() {
    return (
      <div>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              onClick={event => this.setState({ drawerOpen: true })}
              edge="start"
              style={{ marginLeft: -12, marginRight: 20 }}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            {this.props.icon ? this.props.icon() : ''}
            <Typography variant="subtitle1" color="inherit" noWrap>
              {this.props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          open={this.state.drawerOpen}
          variant="temporary"
          onClose={event => this.setState({ drawerOpen: false })}
          onClick={event => this.setState({ drawerOpen: false })}
        >
          <Typography variant="h6" color="inherit" noWrap>
            <NavDrawerList />
          </Typography>
        </Drawer>
      </div>
    );
  }
}

export default SimpleHeader;
