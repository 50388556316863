import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import navBarList from '../NavBarList.js';
import ayahNavBarList from './AyahNavBarList.js';
import Ayah from '../Ayah/Ayah';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SurahSelect from './SurahSelect.js';
import AyahSelect from './AyahSelect.js';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import NavDrawerList from '../NavDrawerList';

const styles = {
  root: {
    flexGrow: 1
  },
  main: {
    paddingTop: 80,
    paddingRight: 20,
    paddingLeft: 20
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
};

class MenuAppBar extends React.Component {
  state = {
    surahMenuAnchorEl: null,
    activeSurah: 1,
    surahMenuActiveItem: '1 - Al-Faatiha | الفاتحة',
    ayahMenuAnchorEl: null,
    activeAyah: 1,
    ayahNumberList: [],
    data: [],
    dataLoaded: false,
    drawerOpen: false,
    settingDialogOpen: false,
    hideArabicText: JSON.parse(localStorage.getItem('hideArabicText')) || false,
    hideEnglishText:
      JSON.parse(localStorage.getItem('hideEnglishText')) || false,
    arabicTextAlignment: localStorage.getItem('arabicTextAlignment') || 'right',
    englishTextAlignment:
      localStorage.getItem('englishTextAlignment') || 'left',
    arabicFontSizeScale: 1,
    englishFontSizeScale: 1
  };
  ayahRefs = [];
  ayahsRendered = false;
  scrollAfterRender = false;
  scrollAfterRenderAyah = 1;
  thisSurahFavorites = [];

  componentDidMount() {
    // get props from /:surah/:ayah
    let surah = this.props.match.params.surah || '1';
    const ayah = this.props.match.params.ayah;

    if (parseInt(surah) < 1 || parseInt(surah) > 114) {
      surah = '1';
    }

    this.setState({
      activeSurah: parseInt(surah),
      surahMenuActiveItem: this.getActiveSurahText(parseInt(surah))
    });

    fetch('https://sacred-message-app.firebaseio.com/' + surah + '.json')
      .then(response => response.json())
      .then(data => this.setState({ data }))
      .then(() => this.setState({ dataLoaded: true }));

    const maxAyahs = ayahNavBarList[parseInt(surah) - 1];
    let list = [];
    for (let i = 1; i <= maxAyahs; i++) {
      list.push(i);
    }
    this.setState({ ayahNumberList: list });

    if (ayah) {
      if (parseInt(ayah) > 0 && parseInt(ayah) < maxAyahs) {
        //save ayah scroll for later
        this.scrollAfterRenderAyah = parseInt(ayah);
        this.scrollAfterRender = true;
        this.setState({ activeAyah: parseInt(ayah) });
      }
    }

    //restore favorites state
    const currentFavorites =
      JSON.parse(localStorage.getItem('favorites')) || [];
    this.thisSurahFavorites = currentFavorites.filter(el => {
      return el.surahNumber === parseInt(surah);
    });
  }

  componentDidUpdate() {
    // update scroll to ayah when refs available
    if (this.ayahRefs[0]) {
      if (this.scrollAfterRender) {
        this.scrollToAyah(this.scrollAfterRenderAyah);
        this.scrollAfterRender = false;
      }
    }

    //restore favorites state
    const currentFavorites =
      JSON.parse(localStorage.getItem('favorites')) || [];
    this.thisSurahFavorites = currentFavorites.filter(el => {
      return el.surahNumber === this.state.activeSurah;
    });
  }

  getActiveSurahText = surahNumber => {
    if (surahNumber) {
      const item = navBarList[surahNumber - 1];
      return item;
    }
    return this.state.surahMenuActiveItem;
  };

  handleSurahMenu = event => {
    this.setState({ surahMenuAnchorEl: event.currentTarget });
  };

  handleAyahMenu = event => {
    this.setState({ ayahMenuAnchorEl: event.currentTarget });
  };

  handleSurahMenuClose = event => {
    event.preventDefault();
    const activeText = this.getActiveSurahText(event.target.value);
    let activeSurah = this.state.activeSurah;
    let list = [];

    if (
      event.target.value !== undefined &&
      activeSurah !== event.target.value
    ) {
      activeSurah = event.target.value;
      this.setState({ dataLoaded: false });
      this.setState({ data: [] });
      // get new data
      const url =
        'https://sacred-message-app.firebaseio.com/' +
        activeSurah.toString() +
        '.json';
      fetch(url)
        .then(response => response.json())
        .then(data => this.setState({ data }))
        .then(() => this.setState({ dataLoaded: true }));

      const maxAyahs = ayahNavBarList[activeSurah - 1];
      for (let i = 1; i <= maxAyahs; i++) {
        list.push(i);
      }
    }
    this.setState({
      surahMenuAnchorEl: null,
      surahMenuActiveItem: activeText,
      activeSurah: activeSurah,
      ayahNumberList: list
    });
  };

  handleAyahMenuClose = event => {
    event.preventDefault();
    const selectedAyah = event.target.value;

    if (event.target.value !== undefined) {
      this.scrollToAyah(selectedAyah);
    }
    this.setState({ ayahMenuAnchorEl: null, activeAyah: selectedAyah });
  };

  scrollToAyah = ayahNumber => {
    if (this.ayahRefs[ayahNumber]) {
      window.scrollTo(0, this.ayahRefs[ayahNumber].offsetTop - 64);
    }
  };

  render() {
    const { classes } = this.props;
    const { surahMenuAnchorEl } = this.state;
    const surahMenuOpen = Boolean(surahMenuAnchorEl);
    const { ayahMenuAnchorEl } = this.state;
    const ayahMenuOpen = Boolean(ayahMenuAnchorEl);
    return (
      <div>
        <div className={classes.root}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                onClick={event => this.setState({ drawerOpen: true })}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
              <SurahSelect
                activeItem={this.state.surahMenuActiveItem}
                open={surahMenuOpen}
                handleMenu={this.handleSurahMenu}
                handleMenuClose={this.handleSurahMenuClose}
                anchor={surahMenuAnchorEl}
                activeSurah={this.state.activeSurah}
              ></SurahSelect>
              <AyahSelect
                activeItem={this.state.activeAyah}
                open={ayahMenuOpen}
                handleMenu={this.handleAyahMenu}
                handleMenuClose={this.handleAyahMenuClose}
                anchor={ayahMenuAnchorEl}
                ayahNumberList={this.state.ayahNumberList}
                activeAyah={this.state.activeAyah}
              ></AyahSelect>
              <Tooltip title="Settings">
                <IconButton
                  onClick={event => this.setState({ settingDialogOpen: true })}
                  edge="start"
                  style={{ marginLeft: 'auto' }}
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Settings"
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Drawer
            open={this.state.drawerOpen}
            variant="temporary"
            onClose={event => this.setState({ drawerOpen: false })}
            onClick={event => this.setState({ drawerOpen: false })}
          >
            <Typography variant="h6" color="inherit" noWrap>
              <NavDrawerList />
            </Typography>
          </Drawer>
        </div>
        <main className={classes.main}>
          <Dialog
            onClose={event => this.setState({ settingDialogOpen: false })}
            open={this.state.settingDialogOpen}
          >
            <DialogTitle id="setting-title">Text Settings </DialogTitle>
            <Divider></Divider>
            <DialogContent dividers={true}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  alignContent: 'space-between'
                }}
              >
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hideEnglishText}
                        onChange={event => {
                          localStorage.setItem(
                            'hideEnglishText',
                            event.target.checked
                          );
                          this.setState({
                            hideEnglishText: event.target.checked
                          });
                        }}
                        value={this.state.hideEnglishText.toString()}
                      />
                    }
                    label="Hide English Text"
                  />
                </FormControl>
                <FormControl>
                  <Select
                    value={this.state.englishTextAlignment}
                    onChange={event => {
                      localStorage.setItem(
                        'englishTextAlignment',
                        event.target.value
                      );
                      this.setState({
                        englishTextAlignment: event.target.value
                      });
                    }}
                    displayEmpty
                    name="englishTextAlignment"
                  >
                    <MenuItem value="left">Left</MenuItem>
                    <MenuItem value="right">Right</MenuItem>
                    <MenuItem value="center">Center</MenuItem>
                  </Select>
                  <FormHelperText>English Text Alignment</FormHelperText>
                </FormControl>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hideArabicText}
                        onChange={event => {
                          localStorage.setItem(
                            'hideArabicText',
                            event.target.checked
                          );
                          this.setState({
                            hideArabicText: event.target.checked
                          });
                        }}
                        value={this.state.hideArabicText.toString()}
                      />
                    }
                    label="Hide Arabic Text"
                  />
                </FormControl>
                <FormControl>
                  <Select
                    value={this.state.arabicTextAlignment}
                    onChange={event => {
                      localStorage.setItem(
                        'arabicTextAlignment',
                        event.target.value
                      );
                      this.setState({
                        arabicTextAlignment: event.target.value
                      });
                    }}
                    displayEmpty
                    name="arabicTextAlignment"
                  >
                    <MenuItem value="left">Left</MenuItem>
                    <MenuItem value="right">Right</MenuItem>
                    <MenuItem value="center">Center</MenuItem>
                  </Select>
                  <FormHelperText>Arabic Text Alignment</FormHelperText>
                </FormControl>
              </div>
            </DialogContent>
            <Divider></Divider>
            <DialogActions>
              <Button
                onClick={event => this.setState({ settingDialogOpen: false })}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {this.state.dataLoaded ? (
            this.state.data.map((item, index) => (
              <div
                ref={instance => {
                  this.ayahRefs[index] = instance;
                }}
                key={item.ayahNumber}
              >
                <Ayah
                  ayah={item}
                  surahNumber={this.state.activeSurah}
                  hideArabicText={this.state.hideArabicText}
                  hideEnglishText={this.state.hideEnglishText}
                  englishTextAlignment={this.state.englishTextAlignment}
                  arabicTextAlignment={this.state.arabicTextAlignment}
                  surahFavorites={this.thisSurahFavorites}
                ></Ayah>
              </div>
            ))
          ) : (
            <CircularProgress />
          )}
        </main>
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MenuAppBar);
