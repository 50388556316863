const navBarList =[
    "1 - Al-Faatiha | الفاتحة",
    "2 - Al-Baqara | البقرة",
    "3 - Aal-i-Imraan | آل عمران",
    "4 - An-Nisaa | النساء",
    "5 - Al-Maaida | المائدة",
    "6 - Al-An'aam | الأنعام",
    "7 - Al-A'raaf | الأعراف",
    "8 - Al-Anfaal | الأنفال",
    "9 - At-Tawba | التوبة",
    "10 - Yunus | يونس",
    "11 - Hud | هود",
    "12 - Yusuf | يوسف",
    "13 - Ar-Ra'd | الرعد",
    "14 - Ibrahim | ابراهيم",
    "15 - Al-Hijr | الحجر",
    "16 - An-Nahl | النحل",
    "17 - Al-Israa | الإسراء",
    "18 - Al-Kahf | الكهف",
    "19 - Maryam | مريم",
    "20 - Taa-Haa | طه",
    "21 - Al-Anbiyaa | الأنبياء",
    "22 - Al-Hajj | الحج",
    "23 - Al-Muminoon | المؤمنون",
    "24 - An-Noor | النور",
    "25 - Al-Furqaan | الفرقان",
    "26 - Ash-Shu'araa | الشعراء",
    "27 - An-Naml | النمل",
    "28 - Al-Qasas | القصص",
    "29 - Al-Ankaboot | العنكبوت",
    "30 - Ar-Room | الروم",
    "31 - Luqman | لقمان",
    "32 - As-Sajda | السجدة",
    "33 - Al-Ahzaab | الأحزاب",
    "34 - Saba | سبإ",
    "35 - Faatir | فاطر",
    "36 - Yaseen | يس",
    "37 - As-Saaffaat | الصافات",
    "38 - Saad | ص",
    "39 - Az-Zumar | الزمر",
    "40 - Al-Ghaafir | غافر",
    "41 - Fussilat | فصلت",
    "42 - Ash-Shura | الشورى",
    "43 - Az-Zukhruf | الزخرف",
    "44 - Ad-Dukhaan | الدخان",
    "45 - Al-Jaathiya | الجاثية",
    "46 - Al-Ahqaf | الأحقاف",
    "47 - Muhammad | محمد",
    "48 - Al-Fath | الفتح",
    "49 - Al-Hujuraat | الحجرات",
    "50 - Qaaf | ق",
    "51 - Adh-Dhaariyat | الذاريات",
    "52 - At-Tur | الطور",
    "53 - An-Najm | النجم",
    "54 - Al-Qamar | القمر",
    "55 - Ar-Rahmaan | الرحمن",
    "56 - Al-Waaqia | الواقعة",
    "57 - Al-Hadid | الحديد",
    "58 - Al-Mujaadila | المجادلة",
    "59 - Al-Hashr | الحشر",
    "60 - Al-Mumtahana | الممتحنة",
    "61 - As-Saff | الصف",
    "62 - Al-Jumu'a | الجمعة",
    "63 - Al-Munaafiqoon | المنافقون",
    "64 - At-Taghaabun | التغابن",
    "65 - At-Talaaq | الطلاق",
    "66 - At-Tahrim | التحريم",
    "67 - Al-Mulk | الملك",
    "68 - Al-Qalam | القلم",
    "69 - Al-Haaqqa | الحاقة",
    "70 - Al-Ma'aarij | المعارج",
    "71 - Nooh | نوح",
    "72 - Al-Jinn | الجن",
    "73 - Al-Muzzammil | المزمل",
    "74 - Al-Muddaththir | المدثر",
    "75 - Al-Qiyaama | القيامة",
    "76 - Al-Insaan | الانسان",
    "77 - Al-Mursalaat | المرسلات",
    "78 - An-Naba | النبإ",
    "79 - An-Naazi'aat | النازعات",
    "80 - Abasa | عبس",
    "81 - At-Takwir | التكوير",
    "82 - Al-Infitaar | الإنفطار",
    "83 - Al-Mutaffifin | المطففين",
    "84 - Al-Inshiqaaq | الإنشقاق",
    "85 - Al-Burooj | البروج",
    "86 - At-Taariq | الطارق",
    "87 - Al-A'laa | الأعلى",
    "88 - Al-Ghaashiya | الغاشية",
    "89 - Al-Fajr | الفجر",
    "90 - Al-Balad | البلد",
    "91 - Ash-Shams | الشمس",
    "92 - Al-Lail | الليل",
    "93 - Ad-Dhuhaa | الضحى",
    "94 - Ash-Sharh | الشرح",
    "95 - At-Tin | التين",
    "96 - Al-Alaq | العلق",
    "97 - Al-Qadr | القدر",
    "98 - Al-Bayyina | البينة",
    "99 - Az-Zalzala | الزلزلة",
    "100 - Al-Aadiyaat | العاديات",
    "101 - Al-Qaari'a | القارعة",
    "102 - At-Takaathur | التكاثر",
    "103 - Al-Asr | العصر",
    "104 - Al-Humaza | الهمزة",
    "105 - Al-Fil | الفيل",
    "106 - Quraish | قريش",
    "107 - Al-Maa'un | الماعون",
    "108 - Al-Kawthar | الكوثر",
    "109 - Al-Kaafiroon | الكافرون",
    "110 - An-Nasr | النصر",
    "111 - Al-Masad | المسد",
    "112 - Al-Ikhlaas | الإخلاص",
    "113 - Al-Falaq | الفلق",
    "114 - An-Naas | الناس"
    ]
    
export default navBarList