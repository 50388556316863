import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';

const SearchRefinement = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL
}) => {
  return items.length === 0 ? (
    <div></div>
  ) : (
    <div>
      <FormControl component="fieldset">
        <FormGroup>
          {items.map(item => (
            <div key={item.label}>
              <StyledBadge
                color="primary"
                badgeContent={item.count}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                <FormControlLabel
                  label={item.label}
                  control={
                    <Checkbox
                      key={item.label}
                      checked={item.isRefined}
                      onChange={e => {
                        e.preventDefault();
                        refine(item.value);
                      }}
                      value={item.value}
                    />
                  }
                />
              </StyledBadge>
            </div>
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

const StyledBadge = withStyles({
  badge: {
    position: 'relative',
    top: '22px',
    right: '20px'
  }
})(Badge);

export default connectRefinementList(SearchRefinement);
