import React from 'react';
import { connectHits, Highlight } from 'react-instantsearch-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import HitsActions from './HitsActions';
import ArabicAyah from '../ArabicAyah';

const HitsDisplay = ({ hits }) => {
  return hits.map(hit => (
    <div
      key={hit.surahNumber.toString() + hit.ayahNumber.toString()}
      style={{ padding: 10 }}
    >
      <Card elevation={2} style={{ backgroundColor: '#ECEFF1' }}>
        <CardContent>
          <Typography variant="subtitle2" align="left" style={{ padding: 10 }}>
            Surah {hit.surahNumber}: {hit.surahName} - Ayah: {hit.ayahNumber}
          </Typography>
          <ArabicAyah align="right">
            <Highlight hit={hit} tagName="mark" attribute="arabicText" />
          </ArabicAyah>
          <Typography variant="h6" align="left" style={{ padding: 10 }}>
            <Highlight hit={hit} tagName="mark" attribute="englishText" />
          </Typography>
          <HitsActions
            surahNumber={hit.surahNumber}
            ayahNumber={hit.ayahNumber}
          />
        </CardContent>
      </Card>
      <Divider variant="middle" />
    </div>
  ));
};

export default connectHits(HitsDisplay);
