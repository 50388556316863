import React from 'react';
import Typography from '@material-ui/core/Typography';

import { connectStats } from 'react-instantsearch-dom';

const Stats = ({ query, nbHits }) => {
  return (
    <Typography variant="h6">
      Found {nbHits} matches for query: "{query}"
    </Typography>
  );
};

export default connectStats(Stats);
