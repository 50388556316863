import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import navBarList from '../NavBarList.js';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const surahSelectStyle = {
  display: 'flex',
  alignItems: 'center'
};

function SurahSelect(props) {
  return (
    <div style={surahSelectStyle}>
      <Typography variant="subtitle1" color="inherit" noWrap>
        {props.activeItem.split(' ')[2]}
      </Typography>
      <Tooltip title="Select a Surah">
        <IconButton
          aria-owns={props.open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={props.handleMenu}
          color="inherit"
        >
          <ExpandMore />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={props.anchor}
        open={props.open}
        onClose={props.handleMenuClose}
      >
        {navBarList.map((surah, index) => (
          <MenuItem
            key={index + 1}
            value={surah}
            selected={index + 1 === props.activeSurah}
            onClick={props.handleMenuClose}
          >
            {surah}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default SurahSelect;
