import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import SearchIcon from '@material-ui/icons/Search';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  connectStateResults,
  PoweredBy,
  Configure,
  Index
} from 'react-instantsearch-dom';

import SimpleHeader from '../SimpleHeader';
import SearchBox from './SearchBox';
import HitsDisplay from './HitsDisplay';
import HitsSurahContent from './HitsSurahContent';
import SearchPagination from './SearchPagination';
import SearchRefinement from './SearchRefinement';
import ClearSearchRefinements from './ClearSearchRefinements';
import Stats from './Stats';
import ResultsPerPage from './ResultsPerPage';

const searchClient = algoliasearch(
  'S9SURB9OLB',
  '254423f37fb4a0682c72efb1094c891b'
);

class Search extends React.Component {
  state = { resultsPerPage: 10 };
  updateResultsPerPage = numResultsPerPage => {
    this.setState({ resultsPerPage: numResultsPerPage });
  };
  render() {
    return (
      <div>
        <div style={{ paddingTop: 80, paddingRight: 20, paddingLeft: 20 }}>
          <SimpleHeader
            title="Search"
            icon={() => <SearchIcon style={{ padding: 10 }} />}
          />
        </div>
        <InstantSearch indexName="MAIN" searchClient={searchClient}>
          <Configure hitsPerPage={this.state.resultsPerPage} />
          <Grid container spacing={1}>
            <Hidden smDown>
              <Grid item xs={2}>
                <RefinementContent />
              </Grid>
            </Hidden>
            <Grid item xs>
              <SearchBox />
              <Index indexName="SURAH_LIST">
                <HitsSurahContent />
              </Index>
              <Content
                resultsPerPage={this.state.resultsPerPage}
                updateResultsPerPage={this.updateResultsPerPage}
              />
            </Grid>
            <Hidden smDown>
              <Grid item xs={1}></Grid>
            </Hidden>
          </Grid>
        </InstantSearch>
      </div>
    );
  }
}
const Content = connectStateResults(
  ({ searchState, searchResults, resultsPerPage, updateResultsPerPage }) =>
    searchState && searchState.query ? (
      searchResults.hits.length === 0 ? (
        <div>
          <Typography variant="h6" align="center">
            No Results Found
          </Typography>
        </div>
      ) : (
        <div>
          <div style={{ paddingTop: 5 }}>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <Stats query={searchState.query} />
              </Grid>
              <Grid item xs>
                <ResultsPerPage
                  resultsPerPage={resultsPerPage}
                  updateResultsPerPage={updateResultsPerPage}
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <HitsDisplay />
          </div>
          <div>
            <SearchPagination />
          </div>
        </div>
      )
    ) : (
      <div>
        <Typography variant="h6" align="center">
          Search the Quran in Arabic or English
        </Typography>
      </div>
    )
);

const RefinementContent = connectStateResults(
  ({ searchState, searchResults }) => {
    return searchState && searchState.query ? (
      <div style={{ paddingLeft: 10 }}>
        <Typography variant="h5" align="left">
          Surahs Filter
        </Typography>
        <SearchRefinement attribute="surahName" limit={114} />
        <div style={{ paddingBottom: '15px' }}>
          <ClearSearchRefinements />
        </div>
        <PoweredBy
          translations={{
            searchBy: ''
          }}
        />
      </div>
    ) : (
      <div></div>
    );
  }
);
export default Search;
