import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class ResultsPerPage extends React.Component {
  render() {
    return (
      <FormControl>
        <Select
          value={this.props.resultsPerPage}
          onChange={e => this.props.updateResultsPerPage(e.target.value)}
          inputProps={{
            name: 'results per page',
            id: 'results-per-page-label-placeholder'
          }}
          displayEmpty
          name="Results Per Page"
        >
          <MenuItem selected={this.props.resultsPerPage === 5} value={5}>
            5
          </MenuItem>
          <MenuItem selected={this.props.resultsPerPage === 10} value={10}>
            10
          </MenuItem>
          <MenuItem selected={this.props.resultsPerPage === 20} value={20}>
            20
          </MenuItem>
          <MenuItem selected={this.props.resultsPerPage === 50} value={50}>
            50
          </MenuItem>
          <MenuItem selected={this.props.resultsPerPage === 100} value={100}>
            100
          </MenuItem>
        </Select>
        <FormHelperText>Results Per Page</FormHelperText>
      </FormControl>
    );
  }
}

export default ResultsPerPage;
