import React from 'react';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearSearchRefinements = ({ items, refine }) => {
  return (
    <Button variant="contained" color="primary" onClick={() => refine(items)}>
      Clear Refinements {'  '}
      <ClearIcon />
    </Button>
  );
};

export default connectCurrentRefinements(ClearSearchRefinements);
