import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

import SimpleHeader from '../SimpleHeader';

class About extends React.Component {
  state = {
    drawerOpen: false
  };
  render() {
    return (
      <div>
        <SimpleHeader
          title="About"
          icon={() => <InfoIcon style={{ padding: 10 }} />}
        />
        <div style={{ paddingTop: 80, paddingRight: 20, paddingLeft: 20 }}>
          <h1 id="about-title">The Sacred Message Of The Glorious Quran</h1>
          <Typography gutterBottom>
            Tafseer and Translation by: Shaykh Muhammad Al-Akili
          </Typography>
          <Typography gutterBottom>
            Published in Canada by: Zawiya Fellowship April 14th 2019
          </Typography>
          <Typography gutterBottom>© 2019 Zawiya Fellowship</Typography>
          <Typography gutterBottom href="mailto:info@zawiyafellowship.com">
            Contact:{' '}
            <a href="mailto:info@zawiyafellowship.com">
              {' '}
              info@zawiyafellowship.com{' '}
            </a>
          </Typography>
          <Typography gutterBottom>
            Online development by: Maqsood Zalgaonker
          </Typography>
          <Typography gutterBottom>
            Quran Arabic Text and Metadata from:{' '}
            <a
              href="http://tanzil.net/docs/resources"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tanzil.net
            </a>
          </Typography>

          <Divider></Divider>
        </div>
      </div>
    );
  }
}

export default About;
